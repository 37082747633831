<template>
  <div>
    <el-dialog
      :title="title + '版本'"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form ref="formInline" :model="formInline" @submit.native.prevent :rules="rules">
          <div class="banben">
            <el-form-item label="版本号" prop="versionCode" style="width:49%">
              <el-input
                placeholder="请输入版本号"
                v-model="formInline.versionCode"
                :disabled="bedisabled"
                style="width: 100%"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="版本名称" prop="versionName" style="width:49%">
              <el-input
                placeholder="请输入版本名称"
                v-model="formInline.versionName"
              
                style="width: 100%"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label="版本说明" prop="phone">
            <el-input
              type="textarea"
              class="text"
              rows="4"
              placeholder="请输入版本说明"
              v-model="formInline.statusRemark"
              ref="phone"
              style="width: 100%"
              maxlength="120"
            ></el-input>
          </el-form-item>
          <div class="sure">
            <el-button size="small" type="primary" @click="createStorage('formInline')">确定</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";

export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      //表单
      formInline: {
        versionCode: "",
        userpwd: "",
        statusRemark: "",
        status: "", //状态
        usertype: 0, //用户类型
        isadmin: 0, //超级管理员
        versionName: ""
      },
      //表单验证
      rules: {
        versionCode: [
          {
            required: true,
            message: "请输入版本号",
            trigger: "blur"
          }
        ],
        versionName: [
          {
            required: true,
            message: "请输入版本名称",
            trigger: "blur"
          }
        ]
      }
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
      this.formInline.statusRemark = "";
      this.formInline.versionCode = "";
    },
    show(item, val, index) {
      this.title = item;
      this.showing = true;
      this.formInline = {};
      this.bedisabled = false;
      if (item == "编辑") {
        this.bedisabled = true;
        val.status = Number(val.status);
        val.usertype = Number(val.usertype);
        val.isadmin = Number(val.isadmin);
        this.formInline = JSON.parse(JSON.stringify(val));
        this.index = index;
      }
    },
    createStorage(formData) {
      // 表单验证-----------

      // 新增
      this.$refs[formData].validate(valid => {
        if (valid) {
          if (this.title == "创建") {
            let parmsterd = {
              versionCode: this.formInline.versionCode,
              versionName: this.formInline.versionName,
              remark: this.formInline.statusRemark
            };
            postRequest("/insertSysWechatVersion", parmsterd).then(res => {
              if (res.status == 200) {
                this.$message({
            duration: 5000,
                  message: "创建成功!",
                  type: "success"
                });
                this.hide();
                this.$refs.formInline.resetFields(); //清空表单
                this.$parent.getFounderList();
              } else {
                this.$message({
            duration: 5000,
                  message: res.message,
                  type: "warning"
                });
              }
            });
          } else {
            this.updateStation(); //调用编辑函数
          }
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning"
          });
        }
      });
    },
    // 编辑
    updateStation() {
      let parmster = {
        versionCode: this.formInline.versionCode,
        versionName: this.formInline.versionName,
        statusRemark: this.formInline.statusRemark
      };
      postRequest("/updateSysWechatVersion", parmster).then(res => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success"
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning"
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
            duration: 5000,
              message: message,
              type: "warning"
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    }
  }
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
</style>