<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">季节配置</div>
      </div>
    </div>

    <div class="hl-content">
      <div style="margin-bottom: 15px">
        <span style="margin-right: 10px">夏季:</span>
        <el-select v-model="sumStarty" placeholder="月">
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">月</span>
        <el-select v-model="sumStartyr" placeholder="日">
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">日</span>
        <span style="margin: 0 10px">至</span>
        <el-select v-model="sumEndy" placeholder="月" style="margin-left: 10px">
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">月</span>
        <el-select v-model="sumEndr" placeholder="日">
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">日</span>
      </div>
      <div>
        <span style="margin-right: 10px">冬季:</span>
        <el-select v-model="winStarty" placeholder="月">
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">月</span>
        <el-select v-model="winStartr" placeholder="日">
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">日</span>
        <span style="margin: 0 10px">至</span>
        <el-select v-model="winEndy" placeholder="月" style="margin-left: 10px">
          <el-option
            v-for="item in option1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">月</span>
        <el-select v-model="winEndr" placeholder="日">
          <el-option
            v-for="item in option2"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span class="zhi">日</span>
      </div>
      <div>
        <el-button v-if="xiugai" class="addIdType" @click="saveSeasonal">
          设置
        </el-button>
      </div>
    </div>

    <addModify ref="addModify" />
    <!-- <disableModify ref="disableModify" /> -->
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";
import addModify from "@/views/seasonalConfig/addModify.vue";
// import disableModify from "@/views/roomState/disableModify.vue";
export default {
  data() {
    return {
      data: {},
      sumStarty: null,
      sumStartyr: null,
      sumEndy: null,
      sumEndr: null,
      winStarty: null,
      winStartr: null,
      winEndy: null,
      winEndr: null,
      xiugai: false,
      shanchu: false,
      option1: [
        {
          value: "01",
          label: "1",
        },
        {
          value: "02",
          label: "2",
        },
        {
          value: "03",
          label: "3",
        },
        {
          value: "04",
          label: "4",
        },
        {
          value: "05",
          label: "5",
        },
        {
          value: "06",
          label: "6",
        },
        {
          value: "07",
          label: "7",
        },
        {
          value: "08",
          label: "8",
        },
        {
          value: "09",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
      ],
      option2: [
        {
          value: "01",
          label: "1",
        },
        {
          value: "02",
          label: "2",
        },
        {
          value: "03",
          label: "3",
        },
        {
          value: "04",
          label: "4",
        },
        {
          value: "05",
          label: "5",
        },
        {
          value: "06",
          label: "6",
        },
        {
          value: "07",
          label: "7",
        },
        {
          value: "08",
          label: "8",
        },
        {
          value: "09",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
        {
          value: "13",
          label: "13",
        },
        {
          value: "14",
          label: "14",
        },
        {
          value: "15",
          label: "15",
        },
        {
          value: "16",
          label: "16",
        },
        {
          value: "17",
          label: "17",
        },
        {
          value: "18",
          label: "18",
        },
        {
          value: "19",
          label: "19",
        },
        {
          value: "20",
          label: "20",
        },
        {
          value: "21",
          label: "21",
        },
        {
          value: "22",
          label: "22",
        },
        {
          value: "23",
          label: "23",
        },
        {
          value: "24",
          label: "24",
        },
        {
          value: "25",
          label: "25",
        },
        {
          value: "26",
          label: "26",
        },
        {
          value: "27",
          label: "27",
        },
        {
          value: "28",
          label: "28",
        },
        {
          value: "29",
          label: "29",
        },
        {
          value: "30",
          label: "30",
        },
        {
          value: "31",
          label: "31",
        },
      ],
    };
  },
  components: {
    addModify,
    // disableModify
  },
  created() {
    if (!this.power.checkedpower("0201001")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    this.xiugai = this.power.checkedpower("0201002");
    this.getFounderList();
  },
  methods: {
    // 获取创始人列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      getRequest("selectHotelSeason").then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          console.log(res.data);
          if (res.data.sumStart != null && res.data.sumEnd != null) {
            this.sumStarty = res.data.sumStart.slice(0, 2);
            this.sumStartyr = res.data.sumStart.slice(3, 5);
            this.sumEndy = res.data.sumEnd.slice(0, 2);
            this.sumEndr = res.data.sumEnd.slice(3, 5);
          }
          if (res.data.winStart != null && res.data.winEnd != null) {
            this.winStarty = res.data.winStart.slice(0, 2);
            this.winStartr = res.data.winStart.slice(3, 5);
            this.winEndy = res.data.winEnd.slice(0, 2);
            this.winEndr = res.data.winEnd.slice(3, 5);
          }
          this.hotelId = res.data.hotelId;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    saveSeasonal() {
      if (
        this.sumStarty == null ||
        this.sumStarty == undefined ||
        this.sumStarty == "" ||
        this.sumStartyr == null ||
        this.sumStartyr == undefined ||
        this.sumStartyr == "" ||
        this.sumEndy == null ||
        this.sumEndy == undefined ||
        this.sumEndy == "" ||
        this.sumEndr == null ||
        this.sumEndr == undefined ||
        this.sumEndr == ""
      ) {
        this.$message({
          duration: 5000,
          message: "请配置完整夏季日期！",
          center: true,
        });
        return;
      }
      if (
        this.winStarty == null ||
        this.winStarty == undefined ||
        this.winStarty == "" ||
        this.winStartr == null ||
        this.winStartr == undefined ||
        this.winStartr == "" ||
        this.winEndy == null ||
        this.winEndy == undefined ||
        this.winEndy == "" ||
        this.winEndr == null ||
        this.winEndr == undefined ||
        this.winEndr == ""
      ) {
        this.$message({
          duration: 5000,
          message: "请配置完整冬季日期！",
          center: true,
        });
        return;
      }
      let parms = {
        hotelId: this.hotelId,
        sumStart: this.sumStarty + "-" + this.sumStartyr,
        sumEnd: this.sumEndy + "-" + this.sumEndr,
        winStart: this.winStarty + "-" + this.winStartr,
        winEnd: this.winEndy + "-" + this.winEndr,
      };
      console.log(parms);
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("updateHotelSeason", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "设置成功！",
            type: "success",
            center: true,
          });
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  margin-top: 10px;
  width: 100px;
  height: 35px;
  background: #387dff;
  color: #fff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.el-form-item {
  margin-bottom: 20px;
}
.zhi {
  margin: 0 10px;
}
.el-select {
  width: 80px;
}
.el-form-item {
  width: 120px;
}
</style>